import Vue from "vue";
import moment from "moment";

Vue.filter("formatCurrency", function (
    value,
    significantDigits,
    fractionalDigits = 2
) {
    if (isNaN(value)) {
        return value;
    } else {
        const options = { maximumFractionDigits: fractionalDigits };
        if (significantDigits) {
            options.maximumSignificantDigits = significantDigits;
        }
        return new Intl.NumberFormat("en-gb", options).format(value);
    }
});

Vue.filter("formatDateUK", function (value) {
    return moment(String(value)).format("D MMM YYYY");
});

Vue.filter("numsBeforeDecimal", function (number) {
    if (Number.isInteger(number)) {
        return number.toString().length;
    }
    return number.toString().split(".")[0].length;
});

<!DOCTYPE html>
<template>
  <div class="new-auth-layout">
    <header class="navbar navbar-header navbar-header-fixed">
      <a href="#" id="mainMenuOpen" class="burger-menu" @click="openMainMenu">
				<i class="fa fa-bars"></i>
			</a>
      <div class="navbar-brand">
        <a href="#">
          <img src="@underwriters/src/assets/img/brand/main_transp-icede.png" alt="iCede Logo" width="128" height="48"/>
        </a>
      </div>
      <!-- navbar-brand -->
      <div id="navbarMenu" class="navbar-menu-wrapper">
        <div class="navbar-menu-header">
          <a href="#">
						<img src="@underwriters/src/assets/img/brand/iCedeLogo.png" alt="iCede Logo" width="45" height="45"/>
					</a>
          <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
        </div>
      </div>
      <!-- navbar-menu-wrapper -->
      <div class="navbar-right"></div>
      <!-- navbar-right -->
    </header>
    <!-- navbar -->
    <div class="container-fluid">
      <div class="row row-xs content-row content-padding">
        <div class="d-none d-md-block col-md-4 col-lg-6 bg-primary-light">
          <div class="row row-xs justify-content-center" style="height: 100%">
            <div class="col-12 text-center">
              <span class="helper"></span>
              <scaled-image
                  :src="
                  require(`@underwriters/src/assets/img/icede-client/${iCedeClient}/logos/main_transp.png`)
                "
                  :alt="iCedeClient"
                  :width="450"
                  class="client-logo-centered"
              />
            </div>
            <div class="col-12 text-center row-2">
              <span class="helper"></span>
              <scaled-image
                  v-for="img in getClientExtraLogos()"
                  :key="img"
                  :src="img"
                  :alt="iCedeClient"
                  :width="150"
                  class="client-logo-centered"
              />
              <div v-if="getClientExtraLogos().length === 0" style="height: 150px;"></div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-6 px-4 px-md-0 login-wrapper">
          <div class="row row-xs justify-content-center login-row">
            <div class="col-sm-12">
              <h1 class="text-center">{{ underwritingPortalMode() ? 'Underwriting Portal' : 'Client Portal' }}</h1>
            </div>
            <div class="col-md-8 col-lg-6">
              <slide-y-up-transition mode="out-in" origin="center top">
                <router-view @afterlogin="doAfterLogin"></router-view>
              </slide-y-up-transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <content-footer />
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";
import { LogInIcon } from "vue-feather-icons";
import { SET_SINGLE_CLIENT } from "@/store/mutation-types";

import ScaledImage from "@underwriters/src/components/Global/ScaledImage";
import FeaturesMixin from "@/mixins/features.mixin";
import ContentFooter from "@underwriters/src/components/Auth/AuthContentFooter";

import "@underwriters/src/assets/js/dashforge.js";

// Vendor styles
import "@underwriters/src/assets/js/@fortawesome/fontawesome-free/css/all.min.css";
import "@underwriters/src/assets/js/ionicons/css/ionicons.min.css";

// Dashforge styles
import "@underwriters/src/assets/css/dashforge.css";
import "@underwriters/src/assets/css/dashforge.auth.css";
import "@underwriters/src/assets/css/skin.cool.lazy.css";

// Main Auth Styles
import "../assets/css/auth.css";


export default {
  name: "NewAuthLayout",
  components: { ScaledImage, ContentFooter, SlideYUpTransition, LogInIcon },
  computed: {
    iCedeClient() {
      return process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();
    },
  },
  data: () => ({
    useBackgroundImage: false,
  }),
  mixins: [FeaturesMixin],
  methods: {
    openMainMenu() {
      document.getElementsByTagName("body")[0].classList.add("navbar-nav-show");
    },
    closeMainMenu() {
      document
          .getElementsByTagName("body")[0]
          .classList.remove("navbar-nav-show");
    },
    async doAfterLogin() {
      let toRoute = "New Inquiry";
      if (this.$store.getters.clients.length === 1) {
        // Only a single client available for this user, select it and go straight to the programmes overview.
        this.$store.commit(SET_SINGLE_CLIENT);
        //toRoute = "Programmes";
      }
      if (this.$store.getters.passwordExpired) {
        toRoute = "Password Expired";
      }
      this.$router.push({ name: toRoute });
    },
  },
  created() {
    if (this.hasBrandingStyleSheet) {
      const clientKey = process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();

      import(`../assets/css/${clientKey}.css`).catch(console.error);
    }
  }
};
</script>
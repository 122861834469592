import ApiService from "@/services/api.service";
import Vue from "vue";
import { SET_BIND_REQUEST } from "@underwriters/src/store/mutation-types";
import store from "@/store";

const UnderwritingService = {
  async sendAdditionalInfoToPOHandler(inquiryId, additionalInfo, file) {
    let formData = new FormData();
    formData.append("inquiryId", inquiryId);
    formData.append("file", file);
    formData.append("additionalInfo", additionalInfo);

    try {
      let { data } = await ApiService.post(
        "/progress/additionalInfo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while uploading document:", err);
      return false;
    }
  },
  async requestBindPolicy(inquiryId, bMarkNotBound) {
    let formData = new FormData();
    formData.append("inquiryId", inquiryId);
    formData.append("markNotBound", bMarkNotBound ? "1" : "0");

    try {
      let { data } = await ApiService.post(
        "/progress/requestBindPolicy",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (data.success) {
        await store.dispatch(SET_BIND_REQUEST, {
          inquiryId: inquiryId,
          bindRequest: data.bindRequest
        });

        if (bMarkNotBound) {
          Vue.toasted.success("Successfully marked the policy as not bound.");
        } else {
          Vue.toasted.success("Successfully requested to bind policy");
        }
      }

      return data;
    } catch (err) {
      console.error("occurred while sending request to bind policy:", err);
      return false;
    }
  },
  async getProgrammes(client_id) {
    try {
      let { data } = await ApiService.post(
          'programme',
          {client_id}
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },
  async getProgramme(client_id, master_policy_id) {
    try {
      let { data } = await ApiService.post(
          'getProgramme',
          {client_id, master_policy_id}
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },
  async getLocalPoliciesForProgramme(master_policy_id, client_id) {
    try {
      let { data } = await ApiService.post(
          'programme/localPolicies',
          {master_policy_id, client_id}
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },
  async getLocalPoliciesLike(client_id, term) {
    try {
      let { data } = await ApiService.post(
          'programme/localPoliciesLike',
          {client_id, term}
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },
  async getCoverageDocument(client_id, master_policy_id, local_policy_id) {
    try {
      let { data } = await ApiService.post(
          'programme/coverageDocument',
          {client_id, master_policy_id, local_policy_id}
      );
      return data;
    } catch (err) {
      if (err.response?.status !== 404) {
        // No coverage document for MPol and LPol combination
        console.error(err);
      }

      return {document: null};
    }
  },
  async getActivityFeed(client_id, timeframe) {
    try {
      let { data } = await ApiService.post(
          'activityFeed',
          {client_id, timeframe}
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
};

export default UnderwritingService;

import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import RouteGuardService from "../services/routeGuard.service";

// Components & Views
import CountrySelector from "../components/Inquiry/CountrySelector";
import CommunicationOverview from "../components/Communication/CommunicationOverview";
import CapabilitiesChecker from "../components/Inquiry/CapabilitiesChecker";
import ProgrammeInstructions from "../components/Inquiry/ProgrammeInstructions";
import CoverSelect from "../components/Coverage/CoverSelect";
import CoverReview from "../components/Coverage/CoverReview";
import AddFiles from "../components/FileUpload/AddFiles";
import NewAuthLayout from "../layouts/NewAuthLayout";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/inquiry/countrySelect",
        component: () => import("../layouts/MainLayout.vue"),
        children: [
            {
                path: "/inquiry/",
                redirect: "/inquiry/countrySelect/1",
                name: 'New Inquiry',
                component: () => import("../views/Inquiries.vue"),
                children: [
                    {
                        path: "countrySelect/:reset?",
                        component: CountrySelector,
                        name: "Country Select",
                        props: true
                    },
                    {
                        path: "communication",
                        component: CommunicationOverview,
                        name: "Communication",
                        props: true
                    },
                    {
                        path: "capabilities",
                        component: CapabilitiesChecker,
                        name: "Capabilities",
                        props: true
                    },
                    {
                        path: "programmeInstructions",
                        component: ProgrammeInstructions,
                        name: "Programme Instructions",
                        props: true
                    },
                    {
                        path: "coverSelect",
                        component: CoverSelect,
                        name: "Cover Select",
                        props: true
                    },
                    {
                        path: "coverReview",
                        component: CoverReview,
                        name: "Cover Review",
                        props: true
                    },
                    {
                        path: "addFiles/:inquiryId",
                        component: AddFiles,
                        name: "Add Data to Inquiry",
                        props: true
                    }
                ]
            },
            {
                path: "/inquiries",
                name: "Inquiries",
                component: () => import("../views/Programmes.vue")
            },
            {
                path: "/instructedAccounts",
                name: "Instructed Accounts",
                component: () => import("../views/InstructedAccounts.vue")
            },
            {
                path: "/upcomingRenewals",
                name: "Upcoming Renewals",
                component: () => import("../views/UpcomingRenewals.vue")
            },
            {
                path: "/wiki",
                name: "Country Data Overview",
                component: () => import("../views/Wiki.vue"),
            },
            {
                path: "/wiki/:companyId",
                name: "Country Data",
                props: true,
                component: () => import("../views/WikiCountryData.vue"),
            },
            // {
            //     path: "/reporting",
            //     name: "Reporting",
            //     component: () => import("../views/Reporting.vue")
            // },
            // {
            //     path: "/alerts",
            //     name: "Alerts",
            //     component: () => import("../views/Alerts.vue")
            // },
            {
                path: "/programmes/view/:programmeId",
                name: "Programme Overview",
                props: true,
                component: () => import("../views/ProgrammeOverview.vue")
            },
            {
                path: "/programmes/view/:programmeId/localPolicy/:localPolicyId",
                name: "Local Policy Overview",
                props: true,
                component: () => import('@underwriters/src/views/LocalPolicyOverview.vue'),
            },
            {
                path: '/programmes/view/:programmeId/localPolicy/:localPolicyId/coverage/:coverageDocumentId',
                name: 'Coverage Document',
                props: true,
                component: () => import('@underwriters/src/views/CoverageDocument.vue'),
            },
            {
                path: '/programmes/view/:programmeId/localPolicy/:localPolicyId/location/:locationId',
                name: 'Location',
                props: true,
                component: () => import('@underwriters/src/views/Location.vue')
            },
        ]
    },

    // Authentication Routes
    {
        path: '/',
        redirect: 'login',
        component: NewAuthLayout,
        children: [
            {
                path: '/login',
                name: 'Login',
                component: () => import('../components/Auth/Login.vue'),
                meta: {openRoute: true}
            },
            {
                path: '/logout',
                name: 'Logout',
                component: () => import('../components/Auth/Login.vue'),
                meta: {openRoute: true}
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('../components/Auth/Register.vue'),
                meta: {openRoute: true}
            },
            {
                path: '/start/u/:userHash',
                name: 'Start',
                props: true,
                component: () => import('../components/Auth/Start.vue'),
                meta: {openRoute: true}
            },
            {
                path: '/forgotPassword/:userEmail?',
                name: 'Forgot Password',
                props: true,
                component: () => import('../components/Auth/ForgotPassword.vue'),
                meta: {openRoute: true}
            },
            {
                path: '/resetPassword/user/:userId/code/:code',
                name: 'Reset Password',
                props: true,
                component: () => import('../components/Auth/ResetPassword.vue'),
                meta: {openRoute: true}
            },
            {
                path: '/passwordExpired',
                name: 'Password Expired',
                component: () => import('../components/Auth/PasswordExpired.vue'),
                meta: {openRoute: false, title: 'Your Password Has Expired', subtitle: 'Please enter a new password'}
            },
            {
                path: '/twoFactor',
                name: 'Two Factor',
                component: () => import('../components/Auth/TwoFactor.vue'),
                meta: {
                    openRoute: false,
                    title: 'Two Factor Authentication',
                    subtitle: 'Authenticate via the code sent to your device'
                }
            },
        ]
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import('../components/Auth/Terms.vue'),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "nav-item active",
    // Added scroll behaviour so that the page scrolls to the top when changing routes.
    scrollBehavior: () => {
        return { x: 0, y: 0 }
    }
});

/**
 * Prevent VueRouter from throwing JS errors for harmless navigation failures.
 * Not necessary on the latest versions but we can't ugprade to Vue3 at this stage (due to Bootstrap)
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }

    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
            || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.redirected)) {
            console.log("Suppressed navigation failure: " + err);
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject);
    }

    return originalReplace.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
            || VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.redirected)) {
            console.log("Suppressed navigation failure: " + err);
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    const route = RouteGuardService.getNextRoute(to, from);

    if (to.name === "Logout") {
        store.dispatch('logout');
    }

    if (route !== '' && route !== to.path) {
        next(route);
        return;
    }

    if (to.meta?.disabled) {
        next({path: '/'});
        return;
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.name);

    document.title = process.env.VUE_APP_TITLE;

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        if (nearestWithTitle.name === "Login" && store.getters.isLoggedIn) {
            nearestWithTitle.name = "User";
        }
        document.title = nearestWithTitle.name + " - " + document.title;
    }


    next();
})

export default router;

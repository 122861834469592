<template>
  <div class="flex w-full h-screen items-center justify-center text-center p-2">
    <div
      class="p-12 border border-gray-300"
      @dragover="dragOver"
      @dragleave="dragLeave"
      @drop="drop"
    >
      <br />
      <h5>{{ title }}</h5>
      <hr />

      <b-overlay :show="isLoading">
        <input
          type="file"
          multiple
          :name="'fields[assetsFieldHandle' + uploaderId + '][]'"
          :id="'assetsFieldHandle' + uploaderId"
          class="w-px h-px opacity-0 overflow-hidden absolute"
          @change="onChange"
          ref="file"
          :accept="this.acceptedFileTypes"
        />
        <label
          :for="'assetsFieldHandle' + uploaderId"
          class="block cursor-pointer w-100 p-4"
        >
          Drop files or click here to upload.
        </label>
        <div class="row" v-if="this.fileList.length">
          <div
            class="col-4"
            v-for="(file, index) in this.fileList"
            :key="index"
          >
            <b-overlay
              :show="file.some(fileVersions => fileVersions.loading === true)"
            >
              <div class="card m-2">
                <div class="card-header">
                  <b-badge
                      v-if="file[file.length - 1]?.countries?.length === 0"
                      variant="secondary"
                  >
                    All Countries
                  </b-badge>
                  <b-badge
                      v-else
                      variant="secondary"
                      v-for="(country, index) in formatCountriesFromArray(
                      file[file.length - 1]?.countries
                    )"
                      :key="index"
                      class="mr-1"
                  >
                    {{ country }}
                  </b-badge>
                  <br /><br />
                </div>
                <div class="card-body">
                  <editable-file-label :files="file" @file-label-updated="updateFileList"></editable-file-label>
                  <br />
                  <h6>{{ file[file.length - 1]?.name }}</h6>
                  <span class="text-muted small">
                    Added:
                    {{
                      $options.filters.formatDateUK(
                        file[file.length - 1]?.document.date_created
                      )
                    }}
                  </span>
                  <br />
                  <br /><br />
                  <button
                    class="btn btn-secondary btn-sm w-100"
                    type="button"
                    @click="openFileVersionListModal(index)"
                    :title="'See ' + file.length + ' version(s)'"
                  >
                    <i class="fa fa-list"></i> {{ file.length }}
                  </button>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
        <br />
      </b-overlay>
    </div>

    <b-modal :id="'modal-file-version' + uploaderId" hide-footer size="lg">
      <template v-slot:modal-header>
        <h2><i class="fa fa-book-open"></i>&nbsp;File Versions</h2>
      </template>
      <h5>Are you replacing an existing file or adding an additional file?</h5>
      <hr />
      <div class="row">
        <div class="col-4 offset-4">
          <div
            :class="{
              card: true,
              'm-2': true,
              'selected-card': bUploadedFileIsANewFile
            }"
          >
            <div class="card-header text-center">
              <i class="fa fa-2x fa-file"></i>
            </div>
            <div class="card-body text-center" @click="selectNewFile()">
              <h5 class="card-title">
                <i class="fa fa-plus-circle"></i><br /><br />
                New File
              </h5>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div v-if="fileList.length > 0" class="row">
        <div class="col">
          <button v-b-toggle.collapseExample class="btn btn-secondary w-100">
            Or replace an existing file...
          </button>

          <b-collapse id="collapseExample" class="mt-2">
            <div class="card card-body">
              <div class="row">
                <div
                  class="col-4"
                  v-for="file in fileList"
                  :key="file[file.length - 1].document_id"
                >
                  <div
                    :class="{
                      card: true,
                      'm-2': true,
                      'selected-card': file[file.length - 1].selected
                    }"
                  >
                    <div class="card-header text-center">
                      <i class="fa fa-2x fa-file"></i>
                    </div>
                    <div
                      class="card-body"
                      @click="selectNewFileVersion(file[file.length - 1])"
                    >
                      <h5 class="card-title">{{ getFileLabel(file) }}</h5>
                      <span class="text-muted small">
                        {{
                          $options.filters.formatDateUK(
                            file[file.length - 1].document.date_created
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <br />
          <hr />
          <br />
          <div>
            <b-form-checkbox v-model="bNewFileIsForAllCountries">
              <h6>This file applies to all countries</h6>
            </b-form-checkbox>
          </div>
          <div v-if="!bNewFileIsForAllCountries">
            <generic-country-selector
              :countryList="countryList"
              @update:selectedCountries="updateSelectedCountries"
            ></generic-country-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <br />
        <hr />
        <br />
      </div>
      <div class="row">
        <div class="col text-right">
          <b-button variant="primary" @click="upload()">Submit</b-button>
        </div>
      </div>
    </b-modal>

    <uploader-file-version-modal
      :customModalId="'modal-file-version-list' + uploaderId"
      :fileList="fileList"
      :selectedFileVersionsIndex="selectedFileVersionsIndex"
      @download="download"
      @remove="remove"
    ></uploader-file-version-modal>
  </div>
</template>
<script>
import DocumentsService from "@/services/documents.service";
import DownloadsMixin from "@underwriters/src/mixins/downloads.mixin";
import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import EditableFileLabel from "@underwriters/src/components/FileUpload/EditableFileLabel";
import GenericCountrySelector from "@underwriters/src/components/Global/GenericCountrySelector";
import UploaderFileVersionModal from "@underwriters/src/components/FileUpload/UploaderFileVersionModal";
import FormatterMixin from "@underwriters/src/mixins/formatter.mixin";
import * as Sentry from "@sentry/vue";

export default {
  name: "Uploader",
  props: {
    uploaderId: {
      type: String
    },
    inquiry: {
      type: Object
    },
    title: {
      type: String
    }
  },
  mixins: [DownloadsMixin, FormatterMixin],
  components: {
    EditableFileLabel,
    GenericCountrySelector,
    UploaderFileVersionModal
  },
  data: () => ({
    isLoading: false,
    fileList: [],
    acceptedFileTypes: process.env.VUE_APP_ACCEPTED_FILE_TYPES,
    selectedFileVersionsIndex: -1,
    bUploadedFileIsANewFile: false,
    iNewFileIsForForFileId: null,
    bNewFileIsForAllCountries: true,
    countryList: [],
    selectedCountries: []
  }),
  mounted: function() {
    this.updateFileList();

    this.countryList = this.inquiry.countries.split("<br />");

    this.selectNewFile();
  },
	watch: {
		bNewFileIsForAllCountries(newValue) {
			if (newValue) {
				this.selectedCountries = [];
			}
		}
	},
  methods: {
    onChange() {
      this.$bvModal.show("modal-file-version" + this.uploaderId);
    },
    async upload(iNewVersionOfInquiryToolProgressDocumentMapId = null) {
      if (this.bUploadedFileIsANewFile) {
        iNewVersionOfInquiryToolProgressDocumentMapId = null;
      } else {
        iNewVersionOfInquiryToolProgressDocumentMapId = this
          .iNewFileIsForForFileId;
      }

      this.$bvModal.hide("modal-file-version" + this.uploaderId);

      let filesToUpload = [...this.$refs.file.files];

      if (filesToUpload.length < 1) {
        return;
      }

      this.isLoading = true;
      filesToUpload.forEach(file => {
        DocumentsService.uploadDocument(
          file,
          this.inquiry.id,
          this.uploaderId,
          iNewVersionOfInquiryToolProgressDocumentMapId,
          this.selectedCountries
        )
          .then(() => {
            this.$store.dispatch("loadInquiries").then(() => {
              this.updateFileList();

              this.isLoading = false;
            });
          })
          .catch(error => {
            Sentry.captureException(error);
            console.log(error);
            this.isLoading = false;
          });
      });
    },
    async download(fileId) {
      let file = null;

      this.fileList.forEach(group => {
        if (!file) {
          file = group.find(file => parseInt(file.document_id) === parseInt(fileId));
        } else {
          Vue.set(file, "loading", true);
        }
      });

      if (!file) {
        return;
      }

      let response = await DocumentsService.getDocument(
        file.document.document_id_hash
      );

      if (!response) {
        return;
      }

      this.downloadFile(response, file.document.document_caption);

      Vue.set(file, "loading", false);
    },
    openFileVersionListModal(index) {
      this.selectedFileVersionsIndex = index;

      this.$bvModal.show("modal-file-version-list" + this.uploaderId);
    },
    remove(fileId) {
      let file = null;
			let fileToBeRemoved = null;

      this.fileList.forEach(group => {
        file = group.find(file => file.document_id === fileId);

        if (file) {
          Vue.set(file, "loading", true);
					fileToBeRemoved = file;
        }
      });

      if (!fileToBeRemoved) {
        return;
      }

      Vue.set(fileToBeRemoved, "loading", true);
      DocumentsService.removeDocument(fileToBeRemoved, this.inquiry.id, this.uploaderId)
        .then(() => {
          this.$store.dispatch("loadInquiries").then(() => {
            Vue.set(fileToBeRemoved, "loading", false);
            this.updateFileList();
          });
        })
        .catch(error => {
          console.log(error);
          Sentry.captureException(error);
          Vue.set(fileToBeRemoved, "loading", false);
        });
    },
    updateFileList() {
      this.fileList = cloneDeep(
        this.inquiry?.documents
          .map(group =>
            group
              .filter(
                file =>
                  parseInt(file.inquiry_tool_progress_document_type_id) ===
                    parseInt(this.uploaderId) &&
                  parseInt(file.flag_archived) === 0
              )
              .map(file => {
                return {
                  ...file,
                  loading: false,
                  editing: false,
                  selected: false
                };
              })
          )
          .filter(group => group.length > 0)
      );
    },
    dragOver(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("uploader-hover")) {
        event.currentTarget.classList.add("uploader-hover");
      }
    },
    dragLeave(event) {
      event.currentTarget.classList.remove("uploader-hover");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();

      event.currentTarget.classList.remove("uploader-hover");
    },
    selectNewFileVersion(oFile) {
      this.fileList.forEach(group => {
        group.forEach(file => {
          if (!file) {
            return;
          }

          this.$set(file, "selected", false);
        });
      });

      this.bUploadedFileIsANewFile = false;
      oFile.selected = true;

      this.iNewFileIsForForFileId =
        oFile.new_version_of_inquiry_tool_progress_document_map_id;
    },
    selectNewFile() {
      this.bUploadedFileIsANewFile = true;
      this.iNewFileIsForForFileId = null;

      this.fileList.forEach(group => {
        group.forEach(file => {
          if (!file) {
            return;
          }

          this.$set(file, "selected", false);
        });
      });
    },
    updateSelectedCountries(newValue) {
      this.selectedCountries = newValue;
    },
    getFileLabel(fileVersions) {
      if (fileVersions !== undefined && fileVersions.length > 0) {
        let label = null;
        for (const fileVersion of fileVersions) {
          if (
            fileVersion.document_label !== undefined &&
            fileVersion.document_label !== null
          ) {
            label = fileVersion.document_label;
            break;
          }
        }

        if (label !== null) {
          return label;
        } else {
          return fileVersions[0].document.filename;
        }
      } else {
        return null;
      }
    }
  }
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}

.uploader-hover {
  background-color: #f0f0f0;
}

.uploader {
  background-color: white;
}

.selected-card {
  border: 4px solid #007bff;
}

.modal-file-version-wrapper {
  z-index: 2000;
}
</style>

import {
  CLEAR_PROGRAMMES,
  CLEAR_USER,
  SET_SELECT_CLIENT_ID,
  SET_SINGLE_CLIENT,
  SET_TWO_FACTOR_METHOD,
  SET_USER,
  UPDATE_PASSWORD_EXPIRED,
  UPDATE_TWO_FACTOR_STATUS,
  ACCEPT_TERMS,
  UPDATE_USER_DATE_SESSION_EXPIRY,
  CLEAR_CLASS_TYPES,
  CLEAR_INQUIRIES
} from "@/store/mutation-types";
import * as Sentry from "@sentry/vue"
import AuthService from "@/services/auth.service";
import axios from "axios";

export const user = {
  state: () => ({
    user: {},
    isLoggedIn: false,
    loggingOut: false,
    timeAuthLastChecked: null,
    passwordExpired: false,
    loggingInWithSSO: false,
    selectedClientId: 0,
    producingOffices: [],
    twoFactor: {
      twoFactorTermsAccepted: false,
      twoFactorMethod: 'Email',
      twoFactorAuthNeeded: true,
      twoFactorSetup: false
    },
    producing_office_id: null,
    po_id_set_from_domain: null,
    icede_sub_client: null
  }),
  mutations: {
    [SET_USER](state, { user, passwordExpired, twoFactor }) {
      state.user = user;
      state.isLoggedIn = true;
      state.passwordExpired = passwordExpired;
      state.twoFactor = twoFactor;
    },
    [CLEAR_USER](state) {
      state.user = {};
      state.isLoggedIn = false;
      state.selectedClientId = 0;
    },
    [SET_SELECT_CLIENT_ID](state, { clientId }) {
      state.selectedClientId = clientId;
    },
    [SET_SINGLE_CLIENT](state) {
      if (state.user?.clients?.length) {
        state.selectedClientId = null; //state.user.clients[0].client_id;
      }
    },
    [UPDATE_PASSWORD_EXPIRED](state, { passwordExpired }) {
      state.passwordExpired = passwordExpired;
    },
    [UPDATE_TWO_FACTOR_STATUS](state, { twoFactorSetup, twoFactorAuthNeeded }) {
      state.twoFactor.twoFactorSetup = twoFactorSetup;
      state.twoFactor.twoFactorAuthNeeded = twoFactorAuthNeeded;
    },
    [ACCEPT_TERMS](state) {
      state.twoFactor.twoFactorTermsAccepted = true;
    },
    [SET_TWO_FACTOR_METHOD](state, { method }) {
      state.twoFactor.twoFactorMethod = method;
    },
    [UPDATE_USER_DATE_SESSION_EXPIRY](state, { dtSessionExpiry }) {
      state.user.date_session_expiry = dtSessionExpiry;
    },
    setLoggingOut(state, payload) {
      state.loggingOut = payload.loggingOut;
    },
    setLoggingInWithSSO(state, { loggingIn }) {
      state.loggingInWithSSO = loggingIn;
    },
    setLastUrl(state, payload) {
      state.lastUrlName = payload.name;
    },
    setProducingOffices(state, { offices }) {
      state.producingOffices = offices;
    },
    resetProducingOffice(state) {
      state.producing_office_id = null;
    },
    setPOID(state, { id, fromDomain }) {
      state.po_id_set_from_domain = false;
      state.icede_sub_client = null;
      if (isNaN(parseInt(id))) {
        return;
      }
      state.producing_office_id = parseInt(id);
      axios.defaults.headers.common["PO-ID"] = state.producing_office_id;
      // Handle the case where the PO ID is set from the domain used to access this instance
      // - set the sub-client which is used to get the correct branding images in the views
      if (fromDomain) {
        state.po_id_set_from_domain = true;
        if (id == process.env.VUE_APP_DOMAIN_MAPPED_URL_A_POID) {
          state.icede_sub_client = process.env.VUE_APP_DOMAIN_MAPPED_URL_A_SUB_CLIENT;
        } else if (id == process.env.VUE_APP_DOMAIN_MAPPED_URL_B_POID) {
          state.icede_sub_client = process.env.VUE_APP_DOMAIN_MAPPED_URL_B_SUB_CLIENT;
        }
      }
    },
  },
  actions: {
    loginUser({ commit }, { data }) {
      commit(
          SET_USER,
          { user: data.user, passwordExpired: data.passwordExpired, twoFactor: data.twoFactor }
      );
      Sentry.setUser({
        id: data.user.client_broker_contact_id,
        email: data.user.email,
        ip_address: "{{auto}}"
      });
    },
    async login({ commit, dispatch, state }, { email, password }) {
      commit(CLEAR_USER);
      commit(CLEAR_INQUIRIES);
      commit(CLEAR_PROGRAMMES);
      commit(CLEAR_CLASS_TYPES);
      commit("resetProgramme");
      commit("checkUncheckCountry", { countryId: "all", checked: false });
      commit('resetProducingOffice');

      localStorage.clear();

      try {
        const data = await AuthService.login(email, password);
        if (data && data.success) {
          dispatch('loginUser', { data });
        }
      } catch (err) {
        console.log("Error on login via ajax");
        console.error(err);
        Sentry.captureException(err);
      }
      return state.isLoggedIn;
    },
    async loginWithToken({ dispatch }, { token }) {
      try {
        let data = await AuthService.loginWithToken(token);

        if (data.success) {
          dispatch("loginUser", { data });
        } else {
          console.error("loginWihToken failed:", data);
        }
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        return false;
      }
      return true;
    },
    async logout({ commit }) {
      commit("setLoggingOut", { loggingOut: true });
      commit(CLEAR_USER);
      commit(CLEAR_PROGRAMMES);
      localStorage.clear();
      Sentry.setUser(null);
      try {
        await AuthService.logout();
        commit("setLoggingOut", { loggingOut: false });
      } catch (err) {
        console.log("Error on logout via ajax");
        console.error(err);
      }
    },
    async updatePassword({ commit }, {currentPassword, newPassword}) {
      try {
        const data = await AuthService
            .changePassword(currentPassword, newPassword);
        if (data.success) {
          commit(UPDATE_PASSWORD_EXPIRED, {passwordExpired: false});
        }
        return data;
      } catch (err) {
        console.log("Error when updating password via ajax");
        console.error(err);
      }
    },
    async setPassword({ commit }, {userHash, password}) {
      try {
        const data = await AuthService
            .setAccountPassword(userHash, password);
        if (data !== undefined && data.success) {
          if (data && data.success) {
            commit(
                SET_USER,
                {
                  user: data.user,
                  passwordExpired: data.passwordExpired,
                  twoFactor: data.twoFactor
                }
            );
          }
        }
        return data;
      } catch (err) {
        console.log("Error when setting account password via ajax");
        console.error(err);
      }
    },
    async authenticateTwoFactor({ commit }, {oneTimePassword}) {
      try {
        const data = await AuthService.authenticate(oneTimePassword);
        if (data.success) {
          commit(UPDATE_TWO_FACTOR_STATUS, {twoFactorSetup: true, twoFactorAuthNeeded: false});
        }
        return data;
      } catch (err) {
        console.log("Error when updating password via ajax");
        console.error(err);
      }
    },
    async acceptTerms({ commit }) {
      try {
        const data = await AuthService.acceptTerms();
        console.log('data: ');
        console.log(data);
        if (data && data.success) {
          commit(ACCEPT_TERMS);
        }
        return data;
      } catch (err) {
        console.log("Error when accepting terms via ajax");
        console.error(err);
        console.log('error end');
      }
    },
    async updateDateSessionExpiry({ commit }, { dtSessionExpiry }) {
      try {
        commit(UPDATE_USER_DATE_SESSION_EXPIRY, { dtSessionExpiry: dtSessionExpiry });
      } catch (err) {
        console.log("Error when updating date of session expiry in user object via ajax");
        console.error(err);
      }
    },
    async checkAuthStatus({ state, commit }) {
      console.log('Checking Auth')
      if (!state.loggingOut) {
        try {
          let { data } = await axios.get(
              process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/user",
              {
                withCredentials: true
              }
          );
          if (data.isLoggedIn && !state.loggingOut) {
            commit("setUser", { user: data.user });
          } else {
            commit("setUser", { user: null });
          }
          commit("setLastAuthTime", { time: Date.now() });
        } catch (err) {
          console.log("User not logged in");
          // Sentry.captureException(err);
          console.error(err);
          commit("setUser", { user: null });
        }
      } else {
        console.log("User logging out");
      }
    },
  },
  getters: {
    user: state => state.user,
    offices: state => state.user.offices,
    isLoggedIn: state => state.isLoggedIn,
    loggingOut: state => state.loggingOut,
    timeAuthLastChecked: state => state.timeAuthLastChecked,
    passwordExpired: state => state.passwordExpired,
    clients: state => state.user.clients,
    //client: state => state.user.clients?.find(c => c.client_id == state.selectedClientId) ?? {},
    twoFactorSetup: state => state.twoFactor?.twoFactorSetup,
    twoFactorAuthNeeded: state => state.twoFactor.twoFactorAuthNeeded,
    twoFactorMethod: state => state.twoFactor.twoFactorMethod,
    shouldGotoTwoFactor: state => (state.twoFactor?.twoFactorAuthNeeded || !state.twoFactor?.twoFactorSetup),
    shouldGotoTerms: state => !state.twoFactor?.twoFactorTermsAccepted,
    termsAccepted: state => state.twoFactor?.twoFactorTermsAccepted,
    loggingInWithSSO: state => state.loggingInWithSSO,
    producingOffices: state => state.producingOffices,
    selectedProducingOffice: state => state.producingOffices.find(office => office.company_id === state.producing_office_id),
    producing_office_id: state => state.producing_office_id,
    po_id_set_from_domain: state => state.po_id_set_from_domain,
    icede_sub_client: state => state.icede_sub_client,
  }
};

import store from '../store';
import moment from "moment";
import {formatThousands} from "@underwriters/src/assets/js/utils/numbers";

export const inputTypes = {
    TEXT: 'text',
    EMAIL: 'email',
    DATE: 'date',
    PARTNER: 'partner',
    CLIENT: 'client',
};

export const capabilitiesFields = {
    emailAddress: {
        label: "Your Email Address",
        type: inputTypes.EMAIL,
        required: false,
    },
    partnerName: {
        label: "Partner Name",
        type: inputTypes.PARTNER,
        required: false,
    },
    clientName: {
        label: "Client (Account) Name",
        type: inputTypes.TEXT,
        required: true,
    },
    clientNameAutoComplete: {
        label: "Client (Account) Name",
        type: inputTypes.CLIENT,
        required: true,
    },
    clientRef: {
        label: "Client ID/Reference",
        type: inputTypes.TEXT,
        required: false,
    },
    programmeName: {
        label: "Programme Name",
        type: inputTypes.TEXT,
        required: true,
    },
    inceptionDate: {
        label: "Inception Date",
        type: inputTypes.DATE,
        required: false,
    },
    underwriterName: {
        label: "Underwriter Name",
        type: inputTypes.TEXT,
        required: false,
    },
    brokerName: {
        label: "Broker Name",
        type: inputTypes.TEXT,
        required: false,
    },
    referenceNumber: {
        label: "Reference Number",
        type: inputTypes.TEXT,
        required: false,
    },
};

const FeaturesMixin = {
    methods: {
        isClient(...clientKeys) {
            for (let clientKey of clientKeys) {
                if (process.env.VUE_APP_ICEDE_CLIENT === clientKey) {
                    return true
                }
            }
            return false
        },
        inquiryOnlyMode() {
            return false;
        },
        underwritingPortalMode() {
            return true;
        },
        usingComplexKeyAuth() {
            return parseInt(process.env.VUE_APP_USING_COMPLEX_KEY_AUTH) === 1;
        },
        usingPricingMatrix() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        usingCoverageElements() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingAddFileToInquiry() {
           return this.underwritingPortalMode();
        },
        usingPolicyTypeSelection() {
            return !this.usingAMENAElements();
        },
        usingTacitRenewal() {
            return this.usingAMENAElements();
        },
        usingContribution() {
            return this.usingAMENAElements();
        },
        usingIssuingOffice() {
            return this.usingAMENAElements() && store.getters.userIsProducing;
        },
        usingPartnerDropdown() {
            return this.usingAMENAElements();
        },
        usingDateOfRate() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA) && !this.usingAMENAElements();
        },
        usingSingleDetailsPane() {
            return this.usingAMENAElements();
        },
        usingMinimumRequirements() {
            return !this.usingAMENAElements();
        },
        hasExtraHeaderRow() {
            return !this.usingAMENAElements();
        },
        usingFOSNonAdm() {
            return !this.usingAMENAElements();
        },
        usingFinc() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },
        usingPolicyLimit() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingSingleHeader() {
            return this.isClient([process.env.VUE_APP_ICEDE_CLIENT_DEFAULT, process.env.VUE_APP_ICEDE_CLIENT_AVIVA]);
        },
        usingFinanceElements() {
            if(this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA)) {
                return false;
            } else {
                return !this.usingAMENAElements();
            }
        },
        usingKeyContacts() {
            if(this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA)) {
                return false;
            } else {
                return !this.usingAMENAElements();
            }
        },
        usingAMENAElements() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AMENA);
        },
        usingCountryPartnerInput() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO, process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },
        showAdditionalWikiInfoInTooltip() {
            !this.usingCountryPartnerInput();
        },
        usingCentralColletion () {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingMaxPolicyLimit() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingLocalTokenAuth() { // This is for inquiry users only.
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO, process.env.VUE_APP_ICEDE_CLIENT_DEFAULT, process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },
        usingProducingOfficeDropdown() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO, process.env.VUE_APP_ICEDE_CLIENT_DEFAULT, process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },
        usingPolicyLimitMaxLimit() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingClientAutoComplete() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        usingSSOAuth() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },
        usingClaimsFeatures() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        shouldShowCapabilitiesBanner() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO);
        },
        hasBrandingStyleSheet() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },
        showPremiumDataInLists() {
            return !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG);
        },
        showHandlersInLists() {
            return !this.isClient([process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_AVIVA]);
        },
        canEditUserDetails() {
            // Don't think any deployments need this at the moment, but without this it is flashing up before 
            //  redirecting during the login process
            return this.usingComplexKeyAuth() && false;
        },
        getIndicativePremiumLabel() {
            if (this.usingAMENAElements()) {
                return 'Gross Premium';
            } else {
                return 'Indicative Premium';
            }
        },
        getLocalPolicyLabel() {
            if (this.usingAMENAElements()) {
                return 'Local Capability';
            } else {
                return 'Local Policy';
            }
        },
        getInstructCoordinatorLabel() {
            if (this.usingAMENAElements()) {
                return 'Input Premium Data by Country';
            } else {
                return 'Continue';
            }
        },
        getSubmitIntructionsLabel() {
            if (this.usingAMENAElements()) {
                return 'Submit'
            } else {
                return 'Instruct MCoE';
            }
        },
        getClientNameLabel() {
            if (this.usingAMENAElements()) {
                return 'Customer (Account) Name';
            } else {
                return 'Client (Account) Name';
            }
        },
        getInsertClientNameLabel() {
            return 'Insert ' + this.getClientNameLabel();
        },
        getNavigationTitle() {
            if (this.usingAMENAElements()) {
                return 'AMENA - Business Pipeline - New Inquiry Creation';
            } else {
                return this.underwritingPortalMode() ? 'iCede Underwriting Portal' : 'iCede Programme Inquiry';
            }
        },
        getInquiryTitle() {
            if (this.usingAMENAElements()) {
                return 'Request Details';
            } else {
                return 'Multinational Programme - Inquiry Tool';
            }
        },
        canSaveReport() {
            return this.underwritingPortalMode() || (!this.usingAMENAElements() && !this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO, process.env.VUE_APP_ICEDE_CLIENT_DEFAULT, process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_AVIVA));
        },
        capabilitiesPDFExportEnabled() {
            return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO, process.env.VUE_APP_ICEDE_CLIENT_DEFAULT, process.env.VUE_APP_ICEDE_CLIENT_TMG, process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
        },

        activeCapabilitiesFields() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO)) {
                let fields =  {
                    underwriterName: capabilitiesFields.underwriterName,
                    emailAddress: capabilitiesFields.emailAddress,
                    brokerName: capabilitiesFields.brokerName,
                    clientName: capabilitiesFields.clientName,
                    programmeName: capabilitiesFields.programmeName,
                    referenceNumber: capabilitiesFields.referenceNumber,
                    inceptionDate: capabilitiesFields.inceptionDate,
                };
                if (this.usingClientAutoComplete()) {
                    fields.clientName = capabilitiesFields.clientNameAutoComplete;
                }
                return fields;
            }
            let fields = {
                clientName: capabilitiesFields.clientName,
                clientRef: capabilitiesFields.clientRef,
                inceptionDate: capabilitiesFields.inceptionDate,
            };
            if (this.usingPartnerDropdown()) {
                fields[partnerName] = capabilitiesFields.partnerName;
            }
            fields['programmeName'] = capabilitiesFields.programmeName;
            return fields;
        },
        getSSOLoginLabel() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA)) {
                return 'AVIVA Corporate Login';
            } else {
                return 'Login with SSO';
            }
        },
        clientImgSrc(imagePath) {
            let imageDir = store.getters.icede_sub_client != null ?
                store.getters.icede_sub_client.toLowerCase()
                : process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();
            let clientImagePath = `@/assets/img/${imageDir}/${imagePath}`;
            let image;
            try {
                // This String literal is intentionally declared twice because require gets resolved at compile-time.
                image = require(`@/assets/img/${imageDir}/${imagePath}`);
            } catch (e) {
                if (
                    process.env.VUE_APP_ICEDE_CLIENT !==
                    process.env.VUE_APP_ICEDE_CLIENT_DEFAULT
                ) {
                    console.error(
                        `An error occurred while trying to require the client image at the path ${clientImagePath}: `,
                        e
                    );
                }
            }
            if (image === undefined) {
                // Fallback to placeholder iCede logo
                image = require("@/assets/img/logo.png");
            }
            return image;
        },
        formatThousandsMixin: formatThousands,
        imageSourceMixin: imageName => process.env.VUE_APP_API_ENDPOINT + '/image/' + imageName,
        liveTextMixin: function(inception, expiry) {
            if (!moment(inception).isValid() || !moment(expiry).isValid()) {
                return ' - ';
            }
            if (moment(inception).isAfter(new Date())) {
                return 'Not live';
            } else if (moment(inception).isBefore(new Date()) && moment(expiry).isAfter(new Date())) {
                return 'Live';
            } else {
                return 'Expired';
            }
        },
        formatDateUKMixin: function (rawDate) {
            return moment(String(rawDate)).format('D MMM YYYY');
        },
        getClientSpecificTitle() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG)) {
                return "TMGO Client Portal";
            }
            return "Client Portal";
        },
        getClientExtraLogos() {
            if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_TMG)) {
                return [
                    require('@underwriters/src/assets/img/icede-client/tmg/logos/tmnf_main_transp.png'),
                    require('@underwriters/src/assets/img/icede-client/tmg/logos/sn_main_transp.png'),
                    require('@underwriters/src/assets/img/icede-client/tmg/logos/hcc_main_transp.png'),
                ];
            }
            return [];
        },
        getLoginFormLabel() {
            switch (process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    return 'Email address';
                default:
                    return 'Email address';
            }
        },
        getLoginFormInputType() {
            switch (process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    return 'text';
                default:
                    return 'email';
            }
        },
        getLoginFormAutocomplete() {
            switch (process.env.VUE_APP_ICEDE_CLIENT) {
                case process.env.VUE_APP_ICEDE_CLIENT_TMG:
                    return 'email';
                default:
                    return 'username email';
            }
        },
    }
}

export default FeaturesMixin

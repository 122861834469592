import Badge from "@underwriters/src/components/Global/Badge";
import BaseAlert from "@underwriters/src/components/Global/BaseAlert";
import BaseButton from "@underwriters/src/components/Global/BaseButton";
import BaseCheckbox from "@underwriters/src/components/Global/BaseCheckbox";
import BaseInput from "@underwriters/src/components/Global/BaseInput";
import BaseDropdown from "@underwriters/src/components/Global/BaseDropdown";
import BaseNav from "@underwriters/src/components/Global/BaseNav";
import BasePagination from "@underwriters/src/components/Global/BasePagination";
import BaseProgress from "@underwriters/src/components/Global/BaseProgress";
import BaseRadio from "@underwriters/src/components/Global/BaseRadio";
import BaseSlider from "@underwriters/src/components/Global/BaseSlider";
import BaseSwitch from "@underwriters/src/components/Global/BaseSwitch";
import BaseTable from "@underwriters/src/components/Global/BaseTable";
import BaseHeader from "@underwriters/src/components/Global/BaseHeader";
import Card from "@underwriters/src/components/Global/Card";
import StatsCard from "@underwriters/src/components/Global/StatsCard";
import Modal from "@underwriters/src/components/Global/Modal";
import TabPane from "@underwriters/src/components/Global/Tabs/TabPane";
import Tabs from "@underwriters/src/components/Global/Tabs/Tabs";

export default {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSlider.name, BaseSlider);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(Card.name, Card);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(Modal.name, Modal);
    Vue.component(TabPane.name, TabPane);
    Vue.component(Tabs.name, Tabs);
  }
};

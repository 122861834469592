import Vue from "vue";
import router from "./router";
import store from "./store";

// Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Services
import ApiService from './services/api.service';
import AuthService from './services/auth.service';

// Utils
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "./filters/filters.js";

import SideBar from "./layouts/SidebarPlugin";
import GlobalDirectives from "./globalDirectives";
import globalComponents from "./globalComponents";

// Libaries/plugins
import Toasted from "vue-toasted";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@underwriters/src/assets/js/dashforge.js";

// Styles
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/sass/main.scss";
import "./assets/sass/light-bootstrap-dashboard.scss";
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false;

// Sentry - Error logging
let bSentryActive = false;

if (
  process.env.VUE_APP_INQUIRY_API_ENDPOINT !== undefined &&
  process.env.VUE_APP_INQUIRY_API_ENDPOINT !== "" &&
  process.env.VUE_APP_SENTRY_DSN !== undefined &&
  process.env.VUE_APP_SENTRY_DSN !== ""
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_INQUIRY_API_ENDPOINT, /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
  bSentryActive = true;
}

if (bSentryActive) {
    if (store.getters.isLoggedIn && store.getters.user) {
        const user = store.getters.user;
        Sentry.setUser({
            id: user.client_broker_contact_id,
            email: user.email,
            ip: "{{auto}}"
        });
    } else {
        Sentry.setUser(null);
    }
}

Vue.component("v-select", vSelect);
Vue.component("date-picker", Datepicker);

Vue.use(SideBar);
Vue.use(GlobalDirectives);
Vue.use(globalComponents);
Vue.use(BootstrapVue);

Vue.use(Toasted, {
  duration: 8000,
  keepOnHover: true,
  iconPack: "fontawesome",
  action: {
    icon: "window-close",
    class: "toast-close-button",
    onClick: (_, toastObject) => {
      toastObject.goAway(0);
    }
  }
});

// Parse URL from domain
const domain = window.location.host;
if (domain === process.env.VUE_APP_DOMAIN_MAPPED_URL_A) {
  DefaultsService.selectProducingOffice(process.env.VUE_APP_DOMAIN_MAPPED_URL_A_POID, true);
} else if (domain === process.env.VUE_APP_DOMAIN_MAPPED_URL_B) {
  DefaultsService.selectProducingOffice(process.env.VUE_APP_DOMAIN_MAPPED_URL_B_POID, true);
}

function startWithComponent(component) {
  new Vue({
    router,
    store,
    render: h => h(component),
    created() {
        // Watch for changes to the user login status
        this.$store.watch(
            () => store.getters.isLoggedIn,
            (isLoggedIn) => {
                if (isLoggedIn && store.getters.twoFactorAuthNeeded === false) {
                    import('./services/defaults.service').then((service) => {
                        service.default.loadDefaults();
                    });
                }
            }
        );

        // Watch for changes for the 2FA status
        this.$store.watch(
            () => store.getters.twoFactorAuthNeeded,
            (twoFactorAuthNeeded) => {
                if (twoFactorAuthNeeded === false && store.getters.isLoggedIn) {
                    import('./services/defaults.service').then((service) => {
                        service.default.loadDefaults();
                    });
                }
            }
        );
    },
  }).$mount("#app");
}

import("./App.vue")
    .then(module => {
      startWithComponent(module.default);
    })
    .catch(err => {
      console.error("Could not find the App base component...");
      console.error(err);
    });


// Dev log for debugging
Vue.prototype.$devLog = function(...args) {
  if (process.env.NODE_ENV !== "production") {
    // add DEV: to the start of the log message
    args[0] = "DEV ONLY: " + args[0];
    console.log.apply(console, args);
  }
}

ApiService.init(process.env.VUE_APP_API_ENDPOINT);

// Allowed unauthenticated routes
const publicRoutes = ['Login', 'Start', 'Two Factor', 'Reset Password'];

// Wait for Vue Router to be ready before checking authentication
router.onReady(() => {

  if (publicRoutes.includes(router.currentRoute.name)) {
    return; // Do nothing if the user is on a public route
  }

  // Only call AuthService.ping() if the user is NOT on a public route
  let request = AuthService.ping();

  request.then(response => {
    if (response && response.status !== 200) {
      router.push({ name: 'Login' });
    }
  })
  .catch(() => {
    router.push({ name: 'Login' });
  });
});

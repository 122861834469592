<template>
  <td class="wiki-td" 
    :class="{
      'text-center': centered, 
      'capabilities-success': wikiData?.level === 'success',
      'capabilities-warning': wikiData?.level === 'warning',
      'capabilities-danger': wikiData?.level === 'danger',
      }"
      @mouseenter="() => toggleAdditionalInfo(true)" 
      @mouseleave="() => toggleAdditionalInfo(false)" 
    >
		<div v-if="printStyles && (featureType === 'Insured Payable Tax %' || featureType === 'Insured Payable Tax %')">
			<span>{{  wikiData?.additional }}</span>
		</div>
		<span v-else-if="wikiData?.value && wikiData?.value.length !== 0">
			{{ wikiData?.value | formatCurrency(sigFigs + 2) }}
		</span>
    <span v-else><small> - </small></span>
    <br v-if="wikiData?.additional" />
    <button v-if="wikiData?.additional"
            class="btn btn-sm btn-secondary btn-additional"
            :class="{'print-styles': printStyles}"
            @click="() => locked = !locked"
            >
      <i class="fa fa-info"></i>
    </button>
    <text-truncated
      v-if="false"
      :text="
        wikiData?.additional
          ? $options.filters.formatCurrency(wikiData?.additional, sigFigsAdditional + 2)
          : ''
      "
      :max-length="30"
      element="small"
    ></text-truncated>
  </td>
</template>

<script>
import TextTruncated from "@underwriters/src/components/Global/TextTruncated";
export default {
  name: "WikiTableData",
  components: {
    TextTruncated
  },
  props: {
    wikiData: Object,
    centered: {
      type: Boolean,
      default: true
    },
    countryId: {
      type: Number,
      default: 0,
    },
    printStyles: {
      type: Boolean,
      default: false,
    },
		featureType: {
			type: String,
			default: ''
		}
  },
  data: () => ({
    locked: false,
  }),
  computed: {
    sigFigs() {
      return this.$options.filters.numsBeforeDecimal(this.wikiData?.value);
    },
    sigFigsAdditional() {
      return this.$options.filters.numsBeforeDecimal(this.wikiData?.additional);
    },
    selected() { // This should probably be converted to a prop
      const context = this;
      const localPolicy = this.$store.getters.localPolicies.find(
        policy => policy.options.country.country_id == context.countryId
      );
      if (!localPolicy) {
        return;
      }
      return localPolicy.selectedWikiItem?.id == this.wikiData?.id;
    }
  },
  methods: {
    toggleAdditionalInfo(show) {
      if (!this.wikiData?.additional || (!show && this.locked)) {
        return;
      }
      if (show && this.locked) {
        this.locked = false;
      }
      let wikiItem = null;
      if (show) {
        wikiItem = this.wikiData;
      }
      this.$store.dispatch("setPolicySelectedWikiItem", { countryId: this.countryId, wikiItem });
    },
  }
};
</script>
<style scoped>
button.btn.btn-sm.btn-additional {
  font-size: 0.6em;
  border-radius: 50%;
  padding: 0 5px;
}
button.btn-additional.print-styles {
  display: none;
}
td {
  padding: 0 !important;
}
td div {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}
</style>

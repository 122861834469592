export const ACTIVITY_FEED_TIMEFRAME = Object.freeze({
    SINCE_LAST_LOGIN: 0,
    IN_LAST_WEEK: 1,
    IN_LAST_MONTH: 2,
});

export const MASTER_POLICY_BIND_STATE = Object.freeze({
    PROSPECT: 1,
    BOUND: 2,
    NOT_BOUND: 3
});

export const USER_TYPE = Object.freeze({
    INQUIRY: 0,
    CLIENT: 1,
    BROKER: 2,
    UNDERWRITER: 3
});
export const COLLECTION_TYPE  = Object.freeze({
    CONST_COLLECTION_TYPE_LOCAL: 0,
    CONST_COLLECTION_TYPE_CENTRAL_FEES: 1,
    CONST_COLLECTION_TYPE_CENTRAL_FULL: 2,
});

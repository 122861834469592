<template>
  <div class="cover-select">
    <div class="row mt-2">
      <div class="col">
        <h5 class="mt-2">Cover Select - Master Coverage Instructions</h5>
        <cover-details></cover-details>
      </div>
    </div>
    <div class="row mt2">
      <div class="col">
        <b-card no-body class="mb-1">
          <b-card-header>
            <h5>
              Master Coverage Template&nbsp;
              <button
                @click="() => (masterCoverVisible = !masterCoverVisible)"
                class="btn btn-secondary btn-sm"
              >
                Toggle Template Visibility&nbsp;
                <i class="fa fa-caret-up"></i>
              </button>
            </h5>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            :visible="masterCoverVisible"
            role="tabpanel"
          >
            <b-card-body>
              <table class="table table-bordered table-hover table-responsive">
                <thead>
                  <tr class="footable--group-row">
                    <th
                      data-group="group1"
                      colspan="1"
                      class="border-right text-center"
                    >
                      Included Covers
                    </th>
                    <th
                      data-group="group2"
                      colspan="1"
                      class="border-right"
                    ></th>
                    <th
                      data-group="group3"
                      colspan="1"
                      class="border-right text-center"
                    >
                      Excluded Covers
                    </th>
                    <th
                      v-if="sublimitColspan > 0"
                      data-group="group4"
                      :colspan="sublimitColspan"
                      class="border-right text-center"
                    >
                      Sublimit
                    </th>
                    <th
                      v-if="deductibleColspan > 0"
                      data-group="group5"
                      :colspan="deductibleColspan"
                      class="border-right text-center"
                    >
                      Deductible
                    </th>
                  </tr>
                  <tr>
                    <th data-group="group1" class="border-right"></th>
                    <th data-group="group2" class="border-right text-center">
                      <strong>&lt;&gt;</strong>
                    </th>
                    <th data-group="group3" class="border-right"></th>
                    <fading-cell
                      tag="th"
                      data-group="group4"
                      :visible="$store.getters.occurrenceVisible"
                    >
                      Occurrence&nbsp;
                      <button
                        class="btn btn-xs btn-secondary"
                        @click="
                          $store.commit('hideColumn', { column: 'occurrence' })
                        "
                      >
                        <i class="fa fa-minus"></i>&nbsp;Hide
                      </button>
                    </fading-cell>
                    <fading-cell
                      tag="th"
                      data-group="group4"
                      :visible="$store.getters.aggregateVisible"
                    >
                      Aggregate&nbsp;
                      <button
                        class="btn btn-xs btn-secondary"
                        @click="
                          $store.commit('hideColumn', { column: 'aggregate' })
                        "
                      >
                        <i class="fa fa-minus"></i>&nbsp;Hide
                      </button>
                    </fading-cell>
                    <fading-cell
                      tag="th"
                      data-group="group5"
                      :visible="$store.getters.maxVisible"
                    >
                      Max/Standard&nbsp;
                      <button
                        class="btn btn-xs btn-secondary"
                        @click="$store.commit('hideColumn', { column: 'max' })"
                      >
                        <i class="fa fa-minus"></i>&nbsp;Hide
                      </button>
                    </fading-cell>
                    <fading-cell
                      tag="th"
                      data-group="group5"
                      :visible="$store.getters.minVisible"
                    >
                      Minimum&nbsp;
                      <button
                        class="btn btn-xs btn-secondary"
                        @click="$store.commit('hideColumn', { column: 'min' })"
                      >
                        <i class="fa fa-minus"></i>&nbsp;Hide
                      </button>
                    </fading-cell>
                    <fading-cell
                      tag="th"
                      data-group="group5"
                      :visible="$store.getters.percentageVisible"
                    >
                      Percentage&nbsp;
                      <button
                        class="btn btn-xs btn-secondary"
                        @click="
                          $store.commit('hideColumn', { column: 'percentage' })
                        "
                      >
                        <i class="fa fa-minus"></i>&nbsp;Hide
                      </button>
                    </fading-cell>
                  </tr>
                </thead>
                <tbody>
                  <tr class="commonRow">
                    <td class="text-muted">
                      Common
                    </td>
                    <td class="border-0">
                      <div style="width: 35px;"></div>
                    </td>
                    <td></td>
                    <fading-cell :visible="$store.getters.occurrenceVisible">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          class="form-control form-control-sm"
                          v-model="commonOccurrence"
                          placeholder="Occurrence"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            @click="
                              $store.dispatch('updateCoverNumbers', {
                                coverId: 'all',
                                column: 'occurrenceLimit',
                                value: commonOccurrence
                              })
                            "
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.aggregateVisible">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          class="form-control form-control-sm"
                          v-model="commonAggregate"
                          placeholder="Aggregate"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            @click="
                              $store.dispatch('updateCoverNumbers', {
                                coverId: 'all',
                                column: 'aggregateLimit',
                                value: commonAggregate
                              })
                            "
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.maxVisible">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          class="form-control form-control-sm"
                          v-model="commonMax"
                          placeholder="Max/Standard"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            @click="
                              $store.dispatch('updateCoverNumbers', {
                                coverId: 'all',
                                column: 'maxDeductible',
                                value: commonMax
                              })
                            "
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.minVisible">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          class="form-control form-control-sm"
                          v-model="commonMin"
                          placeholder="Minimum"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            @click="
                              $store.dispatch('updateCoverNumbers', {
                                coverId: 'all',
                                column: 'minDeductible',
                                value: commonMin
                              })
                            "
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.percentageVisible">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          class="form-control form-control-sm"
                          v-model="commonPercentage"
                          placeholder="Percentage"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            @click="
                              $store.dispatch('updateCoverNumbers', {
                                coverId: 'all',
                                column: 'percentageDeductible',
                                value: commonPercentage
                              })
                            "
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                        </div>
                      </div>
                    </fading-cell>
                  </tr>
                  <!-- Common row spacer to keep it separate from the normal rows -->
                  <tr class="commonSeparator"></tr>
                  <tr
                    v-for="cover in selectedTemplateCovers"
                    :key="cover.id"
                    :class="{ 'table-secondary': !cover.data.included }"
                  >
                    <td
                      :class="{
                        selectedRow: cover.data.included && cover.selected,
                        coverageCell: cover.data.included
                      }"
                      @click="
                        $store.dispatch('selectCoverRow', { id: cover.id })
                      "
                    >
                      <span v-if="cover.data.included">{{
                        cover.data.name
                      }}</span>
                    </td>
                    <td
                      class="border-0"
                      @click="
                        $store.dispatch('selectCoverRow', { id: cover.id })
                      "
                    >
                      <transition name="fade">
                        <button
                          class="btn btn-outline-primary btn-sm"
                          v-if="cover.selected && cover.data.included"
                          @click="
                            $store.dispatch('swapCover', { id: cover.id })
                          "
                        >
                          <i class="fa fa-arrow-right"></i>
                        </button>
                        <button
                          class="btn btn-outline-primary btn-sm"
                          v-if="cover.selected && !cover.data.included"
                          @click="
                            $store.dispatch('swapCover', { id: cover.id })
                          "
                        >
                          <i class="fa fa-arrow-left"></i>
                        </button>
                      </transition>
                    </td>
                    <td
                      :class="{
                        selectedRow: !cover.data.included && cover.selected,
                        coverageCell: !cover.data.included
                      }"
                      @click="
                        $store.dispatch('selectCoverRow', { id: cover.id })
                      "
                    >
                      <span v-if="!cover.data.included">{{
                        cover.data.name
                      }}</span>
                    </td>
                    <fading-cell :visible="$store.getters.occurrenceVisible">
                      <currency-formatted-input
                        :value="cover.data.occurrenceLimit"
                        :disabled="!cover.data.included"
                        placeholder="Occurrence"
                        inputClass="form-control-sm"
                        @input="
                          value =>
                            $store.dispatch('updateCoverNumbers', {
                              coverId: cover.id,
                              column: 'occurrenceLimit',
                              value: value
                            })
                        "
                      >
                      </currency-formatted-input>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.aggregateVisible">
                      <currency-formatted-input
                        :value="cover.data.aggregateLimit"
                        :disabled="!cover.data.included"
                        placeholder="Aggregate"
                        inputClass="form-control-sm"
                        @input="
                          value =>
                            $store.dispatch('updateCoverNumbers', {
                              coverId: cover.id,
                              column: 'aggregateLimit',
                              value: value
                            })
                        "
                      >
                      </currency-formatted-input>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.maxVisible">
                      <currency-formatted-input
                        :value="cover.data.maxDeductible"
                        :disabled="!cover.data.included"
                        placeholder="Max/Standard"
                        inputClass="form-control-sm"
                        @input="
                          value =>
                            $store.dispatch('updateCoverNumbers', {
                              coverId: cover.id,
                              column: 'maxDeductible',
                              value: value
                            })
                        "
                      >
                      </currency-formatted-input>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.minVisible">
                      <currency-formatted-input
                        :value="cover.data.minDeductible"
                        :disabled="!cover.data.included"
                        placeholder="Minimum"
                        inputClass="form-control-sm"
                        @input="
                          value =>
                            $store.dispatch('updateCoverNumbers', {
                              coverId: cover.id,
                              column: 'minDeductible',
                              value: value
                            })
                        "
                      >
                      </currency-formatted-input>
                    </fading-cell>
                    <fading-cell :visible="$store.getters.percentageVisible">
                      <currency-formatted-input
                        :value="cover.data.percentageDeductible"
                        :disabled="!cover.data.included"
                        placeholder="Percentage"
                        inputClass="form-control-sm"
                        @input="
                          value =>
                            $store.dispatch('updateCoverNumbers', {
                              coverId: cover.id,
                              column: 'percentageDeductible',
                              value: value
                            })
                        "
                      >
                      </currency-formatted-input>
                    </fading-cell>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-secondary btn-sm"
                        @click="$bvModal.show('modal-add-cover')"
                      >
                        Add Cover
                      </button>
                    </td>
                    <td class="border-top-0">
                      <!--                <button class="btn btn-success btn-sm" @click="$emit('swap-selected-covers');">Swap Covers<br /><i class="fa fa-exchange-alt"></i></button>-->
                    </td>
                    <td></td>
                    <td v-if="$store.getters.occurrenceVisible"></td>
                    <td v-if="$store.getters.aggregateVisible"></td>
                    <td v-if="$store.getters.maxVisible"></td>
                    <td v-if="$store.getters.minVisible"></td>
                    <td v-if="$store.getters.percentageVisible"></td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col">
        <h5>Local Coverage Instructions</h5>
        <div class="mt-2">
          <table id="footableContacts" class="table table-hover">
            <thead>
              <tr class="footable--group-row">
                <th data-group="group1" colspan="1" class="border-right"></th>
                <th
                  data-group="group2"
                  colspan="2"
                  class="border-right text-center"
                >
                  Local Limits & Deductibles
                </th>
                <th
                  data-group="group3"
                  colspan="2"
                  class="border-right text-center"
                >
                  Other Instructional Information
                </th>
              </tr>
              <tr>
                <th data-group="group1" class="border-right">
                  Local Instructions
                </th>
                <th data-group="group2">Overall Policy Limit</th>
                <th data-group="group2" class="border-right">
                  Overall Policy Deductible
                </th>
                <th data-group="group3">Turnover</th>
                <th data-group="group3" class="border-right">
                  Employee Numbers
                </th>
              </tr>
            </thead>
            <tbody id="tblContacts">
              <tr class="commonRow">
                <td class="text-muted">
                  Common
                </td>
                <td>
                  <div class="input-group">
                    <currency-formatted-input
                      class="form-control form-control-sm"
                      placeholder="Common Policy Limit"
                      :value="overallPolicyLimit"
                      @input="value => (overallPolicyLimit = value)"
                    >
                    </currency-formatted-input>
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        @click="
                          $store.dispatch('updatePolicyValue', {
                            countryId: 'all',
                            coverId: '',
                            type: 'overallLimit',
                            value: overallPolicyLimit
                          })
                        "
                      >
                        <i class="fa fa-arrow-down"></i>&nbsp;Apply
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="input-group">
                    <currency-formatted-input
                      class="form-control form-control-sm"
                      placeholder="Common Policy Deductible"
                      :value="overallPolicyDeductible"
                      @input="value => (overallPolicyDeductible = value)"
                    >
                    </currency-formatted-input>
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        @click="
                          $store.dispatch('updatePolicyValue', {
                            countryId: 'all',
                            coverId: '',
                            type: 'overallDeductible',
                            value: overallPolicyDeductible
                          })
                        "
                      >
                        <i class="fa fa-arrow-down"></i>&nbsp;Apply
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="input-group">
                    <currency-formatted-input
                      class="form-control form-control-sm"
                      placeholder="Common Turnover"
                      :value="turnover"
                      @input="value => (turnover = value)"
                    >
                    </currency-formatted-input>
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        @click="
                          $store.dispatch('updatePolicyValue', {
                            countryId: 'all',
                            coverId: '',
                            type: 'turnover',
                            value: turnover
                          })
                        "
                      >
                        <i class="fa fa-arrow-down"></i>&nbsp;Apply
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="input-group">
                    <currency-formatted-input
                      class="form-control form-control-sm"
                      placeholder="Common Employee Numbers"
                      :value="employees"
                      @input="value => (employees = value)"
                    >
                    </currency-formatted-input>
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        @click="
                          $store.dispatch('updatePolicyValue', {
                            countryId: 'all',
                            coverId: '',
                            type: 'employees',
                            value: employees
                          })
                        "
                      >
                        <i class="fa fa-arrow-down"></i>&nbsp;Apply
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- Common row spacer to keep it separate from the normal rows -->
              <tr class="commonSeparator"></tr>
              <tr
                v-for="data in $store.getters.localPolicies"
                :key="data.options.country.id"
              >
                <td>
                  {{ data.options.country.value }}
                </td>
                <td>
                  <currency-formatted-input
                    class="form-control form-control-sm"
                    :placeholder="data.options.country.value + ' Policy Limit'"
                    :value="data.overallLimit"
                    @input="
                      value =>
                        $store.dispatch('updatePolicyValue', {
                          countryId: data.options.country.id,
                          coverId: '',
                          type: 'overallLimit',
                          value: value
                        })
                    "
                  >
                  </currency-formatted-input>
                </td>
                <td>
                  <currency-formatted-input
                    class="form-control form-control-sm"
                    :placeholder="
                      data.options.country.value + ' Policy Deductible'
                    "
                    :value="data.overallDeductible"
                    @input="
                      value =>
                        $store.dispatch('updatePolicyValue', {
                          countryId: data.options.country.id,
                          coverId: '',
                          type: 'overallDeductible',
                          value: value
                        })
                    "
                  >
                  </currency-formatted-input>
                </td>
                <td>
                  <currency-formatted-input
                    class="form-control form-control-sm"
                    :placeholder="data.options.country.value + ' Turnover'"
                    :value="data.turnover"
                    @input="
                      value =>
                        $store.dispatch('updatePolicyValue', {
                          countryId: data.options.country.id,
                          coverId: '',
                          type: 'turnover',
                          value: value
                        })
                    "
                  >
                  </currency-formatted-input>
                </td>
                <td>
                  <currency-formatted-input
                    class="form-control form-control-sm"
                    :placeholder="
                      data.options.country.value + ' Employee Numbers'
                    "
                    :value="data.employees"
                    @input="
                      value =>
                        $store.dispatch('updatePolicyValue', {
                          countryId: data.options.country.id,
                          coverId: '',
                          type: 'employees',
                          value: value
                        })
                    "
                  >
                  </currency-formatted-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr />
    <div class="row mb-3">
      <div class="col">
        <button
          class="btn btn-primary"
          :disabled="saving || (!underwritingPortalMode() && $store.getters.submitted)"
          @click="uncheckAllCountries"
        >
          <i class="fa fa-times"></i>&nbsp; Clear Selections
        </button>
      </div>
      <div class="col" v-show="canSaveReport()">
        <span v-if="saving">
          <i class="fa fa-sync fa-spin"></i>&nbsp;Saving Programme...
        </span>
        <tooltip-button
          v-else
          text="Save Report Only"
          icon="save"
          btn-class="btn-outline-secondary"
          :disabled="(!underwritingPortalMode() && $store.getters.submitted)"
          disabled-tooltip-text="This programme has been instructed - changes cannot be saved"
          disabled-tooltip-level="info"
          disabled-tooltip-position="left"
          @click="saveReport()"
        ></tooltip-button>
      </div>
      <div class="col">
        <button
          class="btn btn-success"
          :disabled="saving || !$store.getters.programmeInstructionsActive"
          @click="$emit('review-local-instructions')"
        >
          <i class="fa fa-file-invoice"></i>&nbsp; Review Local Instructions
        </button>
      </div>
    </div>
    <login-modal
      v-if="!$store.getters.isLoggedIn"
      :email="$store.getters.emailAddress"
      subtitle="Please login before you save"
      login-button-text="Login and Save"
      @logged-in="saveReport"
    ></login-modal>
    <add-cover-modal></add-cover-modal>
  </div>
</template>

<style scoped>
.selectedRow {
  border: 2px solid #2222aa;
  background-color: #cddaea !important;
}
td.selectedRow:hover {
  border: 2px solid #2222aa;
  background-color: #cddaea !important;
}

td.coverageCell {
  cursor: pointer;
}

tr td .input-group input.form-control {
  width: 90px;
}
</style>

<script>
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
import CurrencyFormattedInput from "@underwriters/src/components/Global/CurrencyFormattedInput";
import TooltipButton from "@underwriters/src/components/Global/TooltipButton";
import LoginModal from "@underwriters/src/components/Auth/LoginModal";
import FadingCell from "@underwriters/src/components/Global/FadingCell";
import AddCoverModal from "@underwriters/src/components/Coverage/AddCoverModal";
import CoverDetails from "@underwriters/src/components/Coverage/CoverDetails";
export default {
  name: "CoverSelect",
  components: {
    FadingCell,
    AddCoverModal,
    TooltipButton,
    CurrencyFormattedInput,
    LoginModal,
    CoverDetails,
  },
  props: {},
  data: () => {
    return {
      commonOccurrence: "",
      commonAggregate: "",
      commonMax: "",
      commonMin: "",
      commonPercentage: "",
      overallPolicyLimit: "",
      overallPolicyDeductible: "",
      turnover: "",
      employees: "",
      saving: false,
      masterCoverVisible: false
    };
  },
  mixins: [FeaturesMixin],
  computed: {
    selectedTemplateCovers() {
      const template = this.$store.getters.selectedTemplate;
      if (template) {
        return template.covers;
      } else {
        return [];
      }
    },
    templateNames() {
      return this.$store.getters.templateNames;
    },
    selectedTemplateName() {
      const template = this.$store.getters.selectedTemplate;
      if (template) {
        return template.name;
      } else {
        return "";
      }
    },
    sublimitColspan() {
      const template = this.$store.getters.selectedTemplate;
      if (!template) {
        return 2;
      }
      return (
        (template.occurrenceVisible ? 1 : 0) +
        (template.aggregateVisible ? 1 : 0)
      );
    },
    deductibleColspan() {
      const template = this.$store.getters.selectedTemplate;
      return (
        (template.maxVisible ? 1 : 0) +
        (template.minVisible ? 1 : 0) +
        (template.percentageVisible ? 1 : 0)
      );
    }
  },
  methods: {
    changeTemplate(value) {
      this.$store.dispatch("changeTemplate", { newTemplate: value });
    },
    uncheckAllCountries() {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: false
      });
      this.$store.commit("resetProgramme");
      this.$router.replace({ name: "Country Select" });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Inquiries" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    }
  },
  mounted() {
    // Redirect to Programme Instructions tab if we don't have the data for this tab
    if (!this.$store.getters.programmeInstructionsActive) {
      this.$router.replace({ name: "Programme Instructions" });
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    document.getElementById("myTab").scrollIntoView({ behavior: "smooth" });
  }
};
</script>
